import React from "react"
import { ReactComponent as Bills } from "assets/icons/bills.svg"
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import { ReactComponent as Copy } from "assets/icons/copy.svg"
import { ReactComponent as Phone } from "assets/icons/phone.svg"
import { ReactComponent as Mail } from "assets/icons/mail.svg"
import { ReactComponent as EmptyBill } from "assets/svg/empty-bill.svg"
import { ReactComponent as Pdf } from "assets/icons/pdf.svg"
import { SlideUp } from "components/Animations"
import EmptyState from "components/EmptyState"
import Navbar from "components/Navbar"
import Table from "components/Table"
import Status from "components/Status"
import { billing_history_headers } from "mocks/billsAndPayments"
import DefaultModal from "components/Modals/DefaultModal"
import BillingInformation from "./Components/BillingInformation"
import PaymentHistory from "./Components/PaymentHistory"
import { formatDate } from "utils/formatDate"
import useComponentLogic from "./useComponentLogic"
import { formatCurrency } from "utils/formatCurrency"
import OutstandingBills from "./Components/OutstandingBills"
import ScrollContainer from "components/ScrollContainer"
import BillingPolicy from "./Components/BillingPolicy"

export default function BillsAndPayments() {
    const {
        selectedFilter, setSelectedFilter, selectedPayment, setSelectedPayment, isFetchingBillingHistory,
        selectedPaymentHistory, setSelectedPaymentHistory, billingHistory, isFetchingPaymentHistory,
        paymentHistory, filters, outstandingBills, isFetchingOutstandingBills, getBillingHistoryProxy, get_outstandingBills,
        selectLimit, changePage, institutionPolicy, setViewPolicy, viewPolicy
    } = useComponentLogic();

    return (
      <div>
        <Navbar title="Bills And Payments" />
        <ScrollContainer>
          <SlideUp>
            <div className="p-[24px] flex flex-col md:flex-row gap-[24px] border-b border-b-neutral_stroke_2">
              <section className="flex flex-col justify-between bg-brand_blue rounded-[16px] md:min-w-[342px]">
                <div className="p-[24px] ">
                  <div className="flex justify-center items-center w-[40px] h-[40px] rounded-full bg-[#FFFFFF14] p-[24px mb-[12px]">
                    <Bills />
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-14 text-[#899AB3] font-campton_r">Total Amount Due</p>
                      <p className="text-18 text-neutral_white font-campton_m">
                        {formatCurrency(billingHistory?.totalOutstandingAmount || 0)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-14 text-neutral_black p-[24px] bg-primary_tint_50 rounded-[16px]">
                  <p className="text-[#153E4C] font-campton_b mb-[12px]">For Billing Inquiries</p>
                  <div className="mb-[12px] flex items-center justify-between">
                    <p>
                      <Mail fill="#131515" className="inline mr-[8px] -mt-[2px]" />
                      billing@gabbihealth.com
                    </p>
                    <Copy fill="#748181" className="cursor-pointer hide_tap" />
                  </div>
                  <p>
                    <Phone fill="#131515" className="inline mr-[8px]" />
                    +234-(816)-532-0922
                  </p>
                </div>
              </section>
              <OutstandingBills loading={isFetchingOutstandingBills} outstandingBills={outstandingBills?.bills} />
            </div>
            <div className="p-[24px]">
              <Table
                title="Billing History"
                headers={billing_history_headers}
                data={billingHistory?.bills}
                totalCount={billingHistory?.total}
                perPage={billingHistory?.limit}
                currentPage={billingHistory?.page}
                selectLimit={selectLimit}
                changePage={changePage}
                loading={isFetchingBillingHistory}
                emptyState={
                  <EmptyState
                    illustration={<EmptyBill />}
                    title="It’s Quiet Over Here"
                    description="You currently have no billing history.
                                All your bills will be displayed here"
                  />
                }
                filter={
                  <div className="flex gap-[8px] mt-[24px] sm:mt-0">
                    {filters?.map((item) => (
                      <Status
                        key={item}
                        status={item}
                        className={`
                                        border ${
                                          selectedFilter === item
                                            ? 'border-[#143566] text-[#143566]'
                                            : 'border-neutral_stroke_2 text-neutral_body'
                                        }
                                        cursor-pointer hide_tap
                                        `}
                        onClick={() => setSelectedFilter(item)}
                      />
                    ))}
                  </div>
                }
              >
                <>
                  {billingHistory?.bills?.map((item) => (
                    <tr
                      key={item?._id}
                      className="text-14 text-neutral_black font-campton_r border-b border-b-neutral_stroke_1 cursor-pointer hide_tap"
                      onClick={() => setSelectedPayment(item)}
                    >
                      <td className="px-[10px] flex items-center">
                        <Pdf className="w-[32px] mr-[8px]" />
                        {item?.billId}
                      </td>
                      <td className="px-[10px]">{formatCurrency(item?.amountExpected)}</td>
                      <td className="px-[10px]">{formatCurrency(item?.amountPaid)}</td>
                      <td className="px-[10px] capitalize">{formatDate(item?.createdAt)}</td>
                      <td className="px-[10px] capitalize">{item?.dueDate ? formatDate(item?.dueDate) : 'N/A'}</td>
                      <td className="px-[10px] w-[20px]">
                        <ArrowRight fill="#748181" />
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </div>
          </SlideUp>
          {selectedPayment ? (
            <DefaultModal
              closeModal={() => setSelectedPayment()}
              styles="w-full md:w-[457px]"
              position="modal-right"
              title="Billing Information"
              variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
            >
              <BillingInformation
                selectedPayment={selectedPayment}
                viewHistory={() => setSelectedPaymentHistory('yes')}
                setSelectedPayment={setSelectedPayment}
                getBillingHistoryProxy={getBillingHistoryProxy}
                getOutstandingBills={get_outstandingBills}
                institutionPolicy={institutionPolicy}
                setViewPolicy={setViewPolicy}
              />
            </DefaultModal>
          ) : (
            ''
          )}
          {Object.keys(selectedPaymentHistory).length ? (
            <DefaultModal
              closeModal={() => setSelectedPaymentHistory({})}
              styles="w-full md:w-[457px]"
              position="modal-right"
              title="Payment History"
              variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m"
            >
              <PaymentHistory
                paymentHistory={paymentHistory}
                loading={isFetchingPaymentHistory}
                selectedPayment={selectedPayment}
              />
            </DefaultModal>
          ) : (
            ''
          )}
          {viewPolicy && (
            <BillingPolicy
              setViewPolicy={setViewPolicy}
              institutionPolicy={institutionPolicy}
              institutionInfo={selectedPayment?.institutionInfo}
            />
          )}
        </ScrollContainer>
      </div>
    );
}