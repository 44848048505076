import React, { useState } from "react";
import PropTypes from "prop-types"
import Avatar from "components/Avatar/Avatar";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import Button from "components/Buttons/Button";
import Checkbox from "components/Checkbox";
import Billing from "../Overview/Billing";
import { formatCurrency } from "utils/formatCurrency";
import useComponentLogic from "../../useComponentLogic";
import { PAYSTACK_CURRENCY } from "constants";
import { useDispatch } from "react-redux";
import { showLoader } from "store/modules/global";
import { restructureBills } from "utils/restructureBills";

export default function BillingInformation({
    viewHistory, selectedPayment, setSelectedPayment, getBillingHistoryProxy, getOutstandingBills, institutionPolicy, setViewPolicy
}) {
    const dispatch = useDispatch()
    const bills = restructureBills(selectedPayment?.billDetails)
    const vat = bills?.reduce((acc, item) => acc + item?.totalVat, 0)
    const [partPayment, setPartPayment] = useState(false)
    const paymentPlan = institutionPolicy?.currentBillingPolicy?.paymentPlan
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const outstandingAmount = selectedPayment?.amountExpected - selectedPayment?.amountPaid;
    const installmentAmount = (paymentPlan?.installmentPercentage / 100) * outstandingAmount
    const {
        initializePayment,
        initializingPayment,
        verify_outstanding_bill_payment,
        isVerifyingPayment,
        verify_bill_part_payment
    } = useComponentLogic();

    const callback = () => {
        getBillingHistoryProxy()
        getOutstandingBills()
        setSelectedPayment()
    }

    const onSuccess = (reference) => {
        dispatch(showLoader({ display: true, title: 'Verifying...' }))
        const status = partPayment ? "partial" : "full"
        const makePayment = selectedPayment?.paymentStatus === "partial" ?
            verify_outstanding_bill_payment({ billId: selectedPayment?.billId, reference }) :
            verify_bill_part_payment({ billId: selectedPayment?.billId, reference, status })

        makePayment
            .then(res => {
                if (res?.code === 200) callback()
                dispatch(showLoader({ display: false }))
            })
            .catch(() => dispatch(showLoader({ display: false })))
    }

    const amount = partPayment ? installmentAmount : outstandingAmount

    const handlePayment = () => {
        initializePayment(
            {
                amount,
                currency: PAYSTACK_CURRENCY,
                email: selectedPayment?.institutionInfo?.businessEmailAddress,
                patientId: selectedPayment?._id,
                institutionId: selectedPayment?.institutionInfo?.id,
                description: "Outstanding payment"
            },
            onSuccess
        )
    }

    return (
      <div className="relative">
        <div className="modal-right-body">
          {outstandingAmount ? (
            <section className="border border-neutral_stroke_2 bg-[#F7FBFD] rounded-[16px] font-campton_r mb-[24px] p-[16px]">
              <p className="text-12 text-[#36A1C5]">
                You still have an outstanding payment of {formatCurrency(outstandingAmount)}.{' '}
                <span onClick={viewHistory} className="underline cursor-pointer hide_tap">
                  View payment history{' '}
                </span>
              </p>
            </section>
          ) : (
            ''
          )}
          <Billing selectedPayment={selectedPayment} />
          <div className="mb-[24px]">
            <p className="text-12 text-[#83889D] font-campton_r mb-[8px]">Bill From</p>
            <div className="border border-neutral_stroke_2 rounded-[16px] p-[16px]">
              <Avatar
                url={selectedPayment?.institutionInfo?.logoUrl}
                width="32px"
                height="32px"
                //alt={singleVisit?.provider?.firstName?.split('')[0]}
                //initials={userInitials(item?.provider?.firstName, item?.provider?.lastName)}
                className="bg-brand_secondary w-[32px] h-[32px] mb-[8px] shrink-0"
              />
              <p className="text-14 font-campton_m text-neutral_black capitalize mb-[4px]">
                {selectedPayment?.institutionInfo?.businessName}
              </p>
              <p className="text-14 font-campton_r text-neutral_body leading-[21px] mb-[4px]">
                {selectedPayment?.institutionInfo?.businessEmailAddress}
              </p>
              <p className="text-14 font-campton_r text-neutral_body leading-[21px] capitalize-first">
                {selectedPayment?.institutionInfo?.businessAddress?.address &&
                  `${selectedPayment?.institutionInfo?.businessAddress?.address}, ${' '}
                            ${selectedPayment?.institutionInfo?.businessAddress?.city}, ${' '}
                            ${selectedPayment?.institutionInfo?.businessAddress?.state}`}
                .
              </p>
            </div>
          </div>
          <section className="mb-[24px]">
            <p className="text-12 text-[#83889D] font-campton_r mb-[8px]">Service Details & Summary</p>
            <div className="border border-neutral_stroke_2 rounded-[16px] p-[16px]">
              {bills?.map((bill) => (
                <ServiceItem
                  key={bill?.category}
                  title={bill?.category}
                  services={bill?.services}
                  amount={bill?.totalFee}
                />
              ))}
              <ServiceItem title="VAT" amount={vat} lastItem={true} />
            </div>
          </section>
          {partPayment && (
            <div className="text-14 flex justify-between font-campton_r p-[16px] bg-[#F7FBFD] border border-neutral_stroke_2 rounded-[16px] mb-[24px]">
              <p className="text-neutral_body">{paymentPlan?.installmentPercentage}% part payment</p>
              <p className="text-neutral_black">{formatCurrency(amount)}</p>
            </div>
          )}
          {paymentPlan?.installment && selectedPayment?.paymentStatus === 'unpaid' && (
            <section className="border border-neutral_stroke_2 bg-[#F7FBFD] rounded-[16px] font-campton_r mb-[24px] p-[16px]">
              <p className="text-14 text-neutral_black mb-[4px]">
                Want to make {partPayment ? 'full' : 'part'} payment?
              </p>
              <p className="text-12 text-neutral_body">
                The Hospital allows for a {paymentPlan?.installmentPercentage}% part payment of billing fee.{' '}
                <span
                  className="text-[#36A1C5] underline cursor-pointer hide_tap"
                  onClick={() => setPartPayment(!partPayment)}
                >
                  Make {partPayment ? 'full' : 'part'} payment
                </span>
              </p>
            </section>
          )}
          {outstandingAmount ? (
            <section>
              <Checkbox
                status={agreedToTerms}
                onClick={() => setAgreedToTerms(!agreedToTerms)}
                label={
                  <>
                    I have read and agreed with {selectedPayment?.institutionInfo?.businessName}&apos;s{' '}
                    <span
                      className="text-[#36A1C5] underline cursor-pointer hide_tap"
                      onClick={() => setViewPolicy(true)}
                    >
                      Billing Policy 
                    </span>
                  </>
                }
              />
            </section>
          ) : (
            ''
          )}
        </div>
        <div className="modal-right-button-container">
          <Button
            name="View history"
            theme="transparent"
            className="h-[40px] mr-[16px] text-neutral_body font-campton_m"
            onClick={viewHistory}
          />
          {outstandingAmount ? (
            <Button
              name={`Pay  ${formatCurrency(amount)}`}
              theme="green"
              className="h-[40px] min-w-[150px] border-0 text-neutral_white font-campton_m"
              disabled={!agreedToTerms || initializingPayment}
              loading={initializingPayment || isVerifyingPayment}
              onClick={handlePayment}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
}

const ServiceItem = ({ title, amount, lastItem, services }) => {
    const [display, setDisplay] = useState(false)

    return (
        <>
            <div
                data-testid={title}
                className={`
                text-14 text-neutral_black font-campton_r ${!lastItem ? 'border-b border-b-neutral_stroke_1' : ''}
                cursor-pointer hide_tap flex items-center justify-between`}
                onClick={() => setDisplay(!display)}
            >
                <div className="p-[10px] flex items-center capitalize">
                    <div className="w-[13.2px]">{display ? <ArrowDown fill="#748181" /> : <ArrowRight fill="#748181" />}</div>
                    <p className="ml-[8px]">{title}</p>
                </div>
                <p className="px-[10px] text-right">{formatCurrency(amount)}</p>
            </div>
            {display && services?.map(item =>
                <div
                    key={item?.name}
                    className="text-14 flex justify-between text-neutral_black font-campton_r 
                    border-b border-b-neutral_stroke_1 bg-[#F7F8F8] overflow-y-hidden"
                >
                    <div className="px-[10px] py-[8px] flex items-center capitalize pl-[30px]">
                        {/* <Dot fill="#131515" className="w-[4px]" /> */}
                        <p className="ml-1">{item?.name}</p>
                    </div>
                    <p className="px-[10px] py-[8px] text-right">{formatCurrency(item?.fee)}</p>
                </div>
            )
            }
        </>
    )
}

BillingInformation.propTypes = {
    viewHistory: PropTypes.func,
    selectedPayment: PropTypes.object,
    setViewPolicy: PropTypes.func,
    getBillingHistoryProxy: PropTypes.func,
    getOutstandingBills: PropTypes.func,
    setSelectedPayment: PropTypes.func,
    institutionPolicy: PropTypes.object
};

ServiceItem.propTypes = {
    title: PropTypes.string,
    amount: PropTypes.number,
    lastItem: PropTypes.bool,
    services: PropTypes.array,
}