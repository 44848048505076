import React, { useEffect, useState } from 'react';
import { Search } from 'components/Inputs';
import PropTypes from 'prop-types';
import useTable from 'hooks/useTable';
import Button from 'components/Buttons/Button';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import RadioButtonSelect from 'components/RadioButtonSelect';
import Avatar from 'components/Avatar/Avatar';
import { userInitials } from 'utils/ImagePlaceholder';
import SelectTimeSlot from 'pages/Appointments/BookAppointment/SelectTimeSlot';
import DefaultModal from 'components/Modals/DefaultModal';
import useProviders from 'hooks/fetch/useProviders';
import useDebounce from 'hooks/useDebounce';
import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';
import { useRecordState } from 'store/modules/myRecords';

const SelectProvider = ({ back, next, formData, setProvider, setFormData, provider }) => {
  const { handleSearch, params } = useTable();
  const debounceValue = useDebounce(params.search);
  const [showModal, setShowModal] = useState(false);
  const { loading, get_staff_with_time } = useProviders();
  const [availableStaffs, setAvailableStaffs] = useState();
  const { patientInfo } = useRecordState();
  // console.log(patientInfo);

  const handleType = (item) => {
    setFormData({ ...formData, providerId: item._id, institutionId: item.institution?.id });
    setProvider(item);
    setShowModal(true); 
  };

  useEffect(() => {
    const date = `${dayjs(formData?.appointmentDate).format('YYYY-MM-DD')}`;
    const search = `${debounceValue}`;
    const limit = `10000`
    const page = '1'
    const institutionId = patientInfo.institutionId._id;
    const params = { page, date, search, limit, institutionId };
    get_staff_with_time(params)
      .then((res) => setAvailableStaffs(res?.data || undefined));
  }, [debounceValue]);

  const callback = () => {
    setShowModal(false);
    setFormData({ ...formData, startTime: '', endTime: '' });
  };

  const getProviderDetails = (item) => {
    const locationPart = item?.locationName ? `${item.locationName} | ` : '';
    const specialtyPart = item?.specialty ? `${item.specialty}, ` : '';
    const qualificationPart = item?.qualification ? `${item.qualification}` : '';

    return `${locationPart}${specialtyPart}${qualificationPart}`.trim();
  };

  return (
    <div className="flex flex-col justify-between h-full relative">
      <div className="mb-[24px]">
        <div className="flex items-center mb-[24px]">
          <Button theme="transparent" type="button" className="w-[77px] mr-[12px] h-8" onClick={back}>
            <>
              <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
              Back
            </>
          </Button>
          <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">
            Select Provider
          </h2>
        </div>
        <Search id="search" name="search" placeholder="Search..." onChange={handleSearch} />
        {loading && <Spinner className='h-[50vh]' />}
        {!loading && availableStaffs?.staffs?.length > 0 && (
          <div className="overflow-y-scroll overflow-x-hidden h-[62vh] lg:h-[48vh] mt-2 pb-[60px]">
            {availableStaffs?.staffs?.map((item) => (
              <div
                className="flex justify-between py-4 border-b border-neutral_stroke_2 cursor-pointer"
                data-testid={item?._id}
                key={item?._id}
                onClick={() => handleType(item)}
              >
                <div className="flex space-x-3">
                  <Avatar
                    initials={userInitials(item?.firstName, item?.lastName)}
                    width="32px"
                    url={item?.profilePic}
                    height="32px"
                    className="w-[32px] h-[32px] shrink-0 mx-[8px]"
                  />
                  <div className="font-campton_r">
                    <p className="text-neutral_black text-14 capitalize">
                      {item?.firstName} {item?.lastName} {item?.gender && `(${item?.gender?.split('')[0]})`}
                    </p>
                    <p className="text-12 text-neutral_body">{getProviderDetails(item)}</p>
                  </div>
                </div>
                <RadioButtonSelect
                  showLabel={false}
                  label="provider"
                  variant="border-0 mb-6"
                  isChecked={formData?.providerId === item._id}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {!loading && availableStaffs?.staffs?.length > 0 && (
        <div className="flex justify-end absolute bottom-0 w-full bg-neutral_white py-[4px]">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={back}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name="Next"
            theme="blue"
            type="button"
            onClick={next}
            className="h-[40px] text-16 w-[88px]"
            disabled={!formData?.providerId || !formData.startTime}
          />
        </div>
      )}
      {!loading && !availableStaffs?.staffs?.length ? (
        <div className="hidden sm:block ">
          <EmptyState
            title="No Provider Available"
            description="No available provider within the selected time. Kindly pick another date to continue"
          />
        </div>
      ) : (
        ''
      )}
      {showModal && (
        <DefaultModal
          closeModal={callback}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title="Appointment Details"
        >
          <SelectTimeSlot
            formData={formData}
            provider={provider}
            setFormData={setFormData}
            callback={callback}
            next={() => setShowModal(false)}
          />
        </DefaultModal>
      )}
    </div>
  );
};

export default SelectProvider;
SelectProvider.propTypes = {
  back: PropTypes.func,
  next: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setProvider: PropTypes.func,
  provider: PropTypes.object,
};
