export const billing_history_headers = [
  'Billing ID',
  'Amount Expected (NGN)',
  'Amount Paid (NGN)',
  'Bill Date',
  'Due Date',
  '',
];

export const billing_history_data = [
    {
        _id: '1',
        billingId: 'RED1234567890',
        amount: '45,550.85',
        billDate: 'Jun 27, 2023',
        dueDate: 'Jul 04, 2023'
    }
]