import Button from 'components/Buttons/Button';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import useAppointment from 'hooks/fetch/useAppointment';
import { useAppointmentState } from 'store/modules/appointments';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/formatCurrency';
import { ReactComponent as Check } from 'assets/icons/check-box-selected.svg';
import { ReactComponent as Uncheck } from 'assets/icons/check-box-unselected.svg';
import { useAuthState } from 'store/modules/auth';
import DefaultModal from 'components/Modals/DefaultModal';
import AppointmentPolicy from '../Components/AppointmentPolicy';
import Spinner from 'components/Spinner';
import usePaystackPayment from 'hooks/usePaystackPayment';
import { useDispatch } from 'react-redux';
import { notify, showLoader } from 'store/modules/global';
import { removeSpecificKeys } from 'utils/removeSpecificKeys';
import { useRecordState } from 'store/modules/myRecords';

const Payment = ({ back, formData, data }) => {
  const { patientInfo } = useRecordState();
  const { loggedUser } = useAuthState();
  const dispatch = useDispatch()
  const { initializePayment, loading: initializingPayment } = usePaystackPayment()
  const { get_services, loading: loadingServices } = useAppointment();
  const { get_current_Policy, loading: loadingPolicies } = useAppointment();
  const { book_appointment, loading: isBookingAppointment } = useAppointment();
  const { services, currentPolicy } = useAppointmentState();
  const [partPayment, setPartPayment] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const paymentPlan = currentPolicy?.currentBillingPolicy?.paymentPlan
  const appointmentType = data?.type === 'onSite' ? 'Walk-in' : 'Telehealth'
    // console.log(patientInfo.institutionId.businessName);

  useEffect(() => {
    get_services({ type: 'institution', institutionId: data?.providerInfo?.institutionId });
    get_current_Policy(data?.providerInfo?.institutionId);
  }, []);

  const calculateTotalFees = (data) => {
    if (!Array.isArray(data) || data.length === 0) return 0

    let totalFees = 0;

    data.forEach(item => {
      if (Array.isArray(item.serviceProperties) && item.serviceProperties.length > 0) {
        totalFees += item.serviceProperties[item.serviceProperties.length - 1].totalFee || 0;
      }
    });

    return totalFees;
  }

  const appointmentServices = useMemo(() => {
    return services?.filter((option) => option?.categoryId?.name === 'Appointment' && option.subCategory.includes(appointmentType));
  }, [services])

  const billDetails = useMemo(() => {
    return appointmentServices?.map(item => ({
      category: 'Appointment',
      subCategory: item.subCategory,
      fee: item.serviceProperties[item.serviceProperties.length - 1]?.fee,
      vat: item.serviceProperties[item.serviceProperties.length - 1]?.vat
    }));
  }, [services])

  const partPaymentAmount = () => {
    return (paymentPlan?.installmentPercentage / 100) * calculateTotalFees(appointmentServices);
  };

  const totalAmount = () => {
    if (partPayment) {
      return partPaymentAmount();
    }
    return calculateTotalFees(appointmentServices);
  }

  const onSuccess = (reference) => {
    dispatch(showLoader({ display: true, title: 'Verifying...' }))
    const payload = {
      ...formData,
      amount: calculateTotalFees(appointmentServices).toString(),
      reference: reference,
      institutionId: data?.providerInfo?.institutionId,
      paymentStatus: partPayment ? 'partial' : 'full',
      appointmentDate: dayjs(formData.appointmentDate).format('YYYY-MM-DD'),
      billDetails
    }
    book_appointment(removeSpecificKeys(payload, ['endTime']))
      .finally(() => dispatch(showLoader({ display: false })))
  }

  const bookAppointment = () => {
    const payload = {
      amount: totalAmount(),
      email: loggedUser?.emailAddress,
      patientId: loggedUser?._id,
      institutionId: data?.providerInfo?.institutionId,
      description: 'appointment',
    }
    if (!currentPolicy?.currentBillingPolicy) {
      dispatch(notify({
        display: true,
        status: 'error',
        message: `The Selected provider doesn't have a billing policy. Kindly select another provider`
      }))
    } else {
      initializePayment(payload, onSuccess)
    }
  };

  return (
    <div className="flex flex-col justify-between h-full overflow-y-scroll max-h-[550px]">
      <div>
        <div className="flex items-center mb-[24px]">
          <Button theme="transparent" type="button" className="w-[77px] mr-[12px] h-8" onClick={back}>
            <>
              <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
              Back
            </>
          </Button>
          <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">
            Payment Information
          </h2>
        </div>
        {(loadingServices || loadingPolicies) ? <Spinner className='h-[60vh]' /> : ''}
        {(!loadingServices && !loadingPolicies) && appointmentServices?.length ? (
          <div>
            <div className="border border-neutral_stroke_2 bg-primary_tint_50 rounded-2xl p-4">
              <p className="text-14 font-campton_m text-neutral_body mb-2">Summary</p>
              {appointmentServices?.map((item) => (
                <div className="flex justify-between font-campton_r text-14 mb-4" key={item._id}>
                  <p className="text-neutral_body capitalize">{item.name}</p>
                  <p className="text-neutral_black">{formatCurrency(item.serviceProperties?.[item.serviceProperties.length - 1]?.totalFee)}</p>
                </div>
              ))}
              <div className="flex justify-between font-campton_r text-14 pt-4 border-t border-dashed border-neutral_stroke_2">
                <p className="text-neutral_body">Sub-total</p>
                <p className="text-neutral_black">{formatCurrency(calculateTotalFees(appointmentServices))}</p>
              </div>
              <div className="flex justify-between font-campton_r text-14 mt-4">
                <p className="text-neutral_body">Discount</p>
                <p className="text-neutral_black">{formatCurrency(0)}</p>
              </div>
              <div className="flex justify-between font-campton_r text-14 mt-4">
                <p className="text-neutral_body">Total</p>
                <p className="text-neutral_black">{formatCurrency(calculateTotalFees(appointmentServices))}</p>
              </div>
              {partPayment && (
                <div className="flex justify-between font-campton_r text-14 mt-4 pt-4 border-t border-dashed border-neutral_stroke_2">
                  <p className="text-neutral_body">Part payment amount</p>
                  <p className="text-neutral_black">{formatCurrency(totalAmount())}</p>
                </div>
              )}
            </div>
            {/* {paymentPlan?.installment && ( 
              <div className="border border-neutral_stroke_2 bg-primary_tint_50 rounded-2xl mt-6 p-4 text-14">
                <p className="font-campton_m text-neutral_black mb-1">Want to make part payment?</p>
                <p className="text-neutral_body font-campton_r">
                  The Hospital allows for a {''}
                  <span>
                    {partPayment
                      ? 'full'
                      : `${paymentPlan?.installmentPercentage}% part`}
                  </span>{' '}
                  payment of appointment fee.{' '}
                  <button
                    className="underline text-brand_primary cursor-pointer"
                    onClick={() => setPartPayment((prev) => !prev)}
                  >
                    {`Make ${partPayment ? 'full' : 'part'} payment`}
                  </button>
                </p>
              </div>
            )} */}
            <div className="flex mt-8">
              <button
                type="button"
                data-testid="test-check"
                className="cursor-pointer"
                onClick={() => setConfirm(!confirm)}
              >
                {confirm ? <Check /> : <Uncheck />}
              </button>
              <p className="text-neutral_body font-campton_r text-14 ml-3">
                I have read and agree with {patientInfo.institutionId.businessName}
                <button
                  data-testid="appointment-policy"
                  className="underline text-brand_primary cursor-pointer ml-1"
                  onClick={() => setShowPolicy(true)}
                >
                  Appointment Policy 
                </button>
              </p>
            </div>
          </div>
        ) : ''}
        {(!loadingServices && !loadingPolicies) && !appointmentServices?.length && (
          <div className='h-[50vh] flex justify-center items-center'>
            <p className="font-campton_m text-neutral_black text-center">
              No service Available, Kindly select another provider
            </p>
          </div>
        )}
      </div>
      {(!loadingServices && !loadingPolicies) && appointmentServices?.length ? (
        <div className="flex justify-end mt-4 py-[4px]">
          <Button
            name="Cancel"
            theme="transparent"
            type="button"
            onClick={back}
            className="h-[40px] text-16 w-[79px] mr-[16px]"
          />
          <Button
            name={`Pay ${formatCurrency(totalAmount())}`}
            theme="green"
            loading={initializingPayment || isBookingAppointment}
            disabled={!confirm}
            type="button"
            onClick={bookAppointment}
            className="h-[40px] text-16 w-[180px]"
          />
        </div>
      ) : ''}
      {showPolicy && (
        <DefaultModal
          closeModal={() => setShowPolicy(false)}
          styles="w-full md:w-[457px]"
          position="modal-right"
          variant="py-[14.5px] px-[24px] text-left border-b border-b-neutral_stroke_2 text-16 font-campton_m capitalize"
          title="Appointment Policy"
        >
          <AppointmentPolicy data={data} amount={totalAmount()} />
        </DefaultModal>
      )}
    </div>
  );
};

export default Payment;
Payment.propTypes = {
  back: PropTypes.func,
  formData: PropTypes.object,
  data: PropTypes.object,
};
