import React from 'react';
import Status from 'components/Status';
import Avatar from 'components/Avatar/Avatar';
import dayjs from 'dayjs';
import { userInitials } from 'utils/ImagePlaceholder';
import PropTypes from 'prop-types';
import { useAuthState } from 'store/modules/auth';
import { insertTimeToDate } from 'utils/formatDate';

const AppointmentInfo = ({ data, type }) => {
  const { loggedUser } = useAuthState();

  return (
    <div>
      <div className="border border-neutral_stroke_2 bg-primary_tint_50 p-4 rounded-2xl">
        <p className="font-campton_r text-12 text-neutral_body mb-4">Details</p>
        <div className="flex">
          <Avatar
            url={loggedUser?.profilePic}
            width="32px"
            height="32px"
            textVariant="!text-12"
            alt={loggedUser?.firstName?.split('')[0]}
            initials={userInitials(loggedUser?.firstName, loggedUser?.lastName)}
            className="bg-brand_secondary mr-1.5 w-8 h-8"
          />
          <div className="font-campton_r">
            <p className="text-neutral_black text-14 capitalize">
              {loggedUser?.firstName} {loggedUser?.lastName}
            </p>
            <p className="text-12 text-neutral_body">
              {loggedUser?.emailAddress && `${loggedUser?.emailAddress} |`} {loggedUser?.gNumber}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-4">
          <div className="font-campton_r">
            <p className="text-neutral_body text-12 mb-1">Appointment Type</p>
            <p className="capitalize text-14">{data?.type === 'onSite' ? 'Onsite' : 'Telehealth'}</p>
          </div>
          <div className="font-campton_r">
            <p className="text-neutral_body text-12 mb-1">Appointment Priority</p>
            <Status status={data?.priority} className="!text-12" />
          </div>
        </div>
        <div className="font-campton_r mt-4">
          <p className="text-neutral_body text-12 mb-1">Date</p>
          <p className="capitalize text-14">
            {type !== 'booking' ? dayjs(insertTimeToDate(data.appointmentDate, data.startTime)).format('MMM DD, YYYY') : dayjs(data.appointmentDate).format('MMM DD, YYYY')},
            <span className="ml-2">
              {type !== 'booking' ? dayjs(insertTimeToDate(data.appointmentDate, data.startTime)).format('hh : mm A') : data.startTime} -{' '}
              {type !== 'booking' ? dayjs(insertTimeToDate(data.appointmentDate, data.endTime)).format('hh : mm A') : data.endTime}
            </span>
          </p>
        </div>
        <div className="font-campton_r mt-4">
          <p className="text-neutral_body text-12 mb-1">Notes</p>
          <p className="capitalize text-14">{data?.note || 'N/A'}</p>
        </div>
      </div>
      <div className="border border-neutral_stroke_2 p-4 rounded-2xl mt-6">
        <p className="font-campton_r text-12 text-neutral_body mb-4">Provider Information</p>
        <div className="flex">
          <Avatar
            url={data?.providerInfo?.profilePic}
            width="32px" 
            height="32px"
            textVariant="!text-12"
            alt={data?.providerInfo?.firstName?.split('')[0]}
            initials={userInitials(data?.providerInfo?.firstName, data?.providerInfo?.lastName)}
            className="bg-brand_secondary mr-1.5 w-8 h-8 shrink-0"
          />
          <div className="font-campton_r">
            <p className="text-neutral_black text-14 capitalize">
              {data?.providerInfo?.firstName} {data?.providerInfo?.lastName}{' '}
              {data?.providerInfo?.gender &&
                <span className="text-neutral_body text-12">
                  {`(${data?.providerInfo?.gender?.split('')[0] ?? 'N/A'})`}
                </span>}
            </p>
            <p className="text-12 text-neutral_body">
              {data?.providerInfo?.locationName && `${data?.providerInfo?.locationName} |`}{" "}
              {data?.providerInfo?.specialty && `${data?.providerInfo?.specialty},`}{" "}
              {data?.providerInfo?.qualification}
            </p>
            <p className="text-12 text-neutral_body">{data?.providerInfo?.GPINumber ?? 'N/A'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentInfo;
AppointmentInfo.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string
};
